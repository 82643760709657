// Customizable Area Start
import React, { CSSProperties, ChangeEvent } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  withStyles,
  Snackbar,
  IconButton,
} from "@material-ui/core";
import { Formik } from "formik";
import CloseIcon from "@material-ui/icons/Close";

import AddContactModalController, {
  Props,
} from "./AddContactModalController.web";
import Alert from "@material-ui/lab/Alert";

const styles = (theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      width: "440px",
      padding: "60px 0 40px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      flexShrink: 0,
      borderRadius: "8px 8px 32px 8px",
      background: "#FFF",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
      },
    },

    form: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "17px",
    },

    createContactDescription: {
      width: "100%",
      paddingLeft: "39px",
      color: "#000",
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
    },

    modalTitle: {
      width: "360px",
      color: "#000",
      textAlign: "left",
      fontFamily: "Inter",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
      position: "relative"
    },

    masterInputsContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: "16px",
    },

    inputContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
    },

    inputLabel: {
      color: "#334155",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "22px",
    },

    inputField: {
      width: "360px",
      height: "56px",

      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
      },

      "& .MuiInputBase-input": {
        height: "56px",
        paddingTop: 0,
        paddingBottom: 0,
      },

      "& .MuiFormHelperText-marginDense": {
        marginTop: 0,
      },
    },

    addContactButton: {
      "&:hover": {
        backgroundColor: "#FFC629",
      },
    },

    backButton: {
      "&:hover": {
        backgroundColor: "#000",
      },
    },
  });

const webStyles: Record<string, CSSProperties> = {
  phoneInput: {
    width: "360px",
    height: "56px",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "16px !important",
  },

  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "56px",
    width: "360px",
    borderRadius: "8px",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
  },

  addContactButton: {
    backgroundColor: "#FFC629",
    color: "#0F172A",
  },

  backButton: {
    backgroundColor: "#000",
    color: "#FFF",
  },
};

class AddContactModal extends AddContactModalController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, currentStep, contactData, setStep, hideBackBtn = false } = this.props;
    const { apiError, apiSuccess } = this.state;

    return (
      <Container
        maxWidth={false}
        className={classes.modal}
        onClick={(e) => e.stopPropagation()}
        data-test-id="addContactContainer"
      >
        <Formik
          initialValues={this.getInitialFormValues()}
          validate={this.validate}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            this.createContact(values, contactData?.id);
          }}
        >
          {({ values, handleChange, handleSubmit, errors, touched }) => {
            return (
              <form onSubmit={handleSubmit} className={classes.form}>
                {!currentStep && (
                  <Typography className={classes.createContactDescription}>
                    {this.createContactDescription}
                  </Typography>
                )}
                <Typography gutterBottom className={classes.modalTitle}>
                  {this.showModalTitle()}
                  {
                    contactData &&
                    <IconButton
                      id="closeModalButton"
                      style={{
                        position: "absolute",
                        right: "-14px",
                        top: "-22px",
                        cursor: "pointer"
                      }}
                      onClick={setStep}

                    >
                      <CloseIcon />

                    </IconButton>
                  }
                </Typography>
                <Box className={classes.masterInputsContainer}>
                  <Box className={classes.inputContainer}>
                    <Typography className={classes.inputLabel}>
                      {this.firstName+"*"}
                    </Typography>
                    <TextField
                      className={classes.inputField}
                      id="firstName"
                      name="firstName"
                      variant="outlined"
                      size="small"
                      placeholder={this.firstName}
                      onChange={handleChange}
                      value={values.firstName}
                      error={touched.firstName && Boolean(errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                      data-test-id="firstNameInput"
                      InputProps={{
                        style: {
                          height: "56px",
                        },
                      }}
                    />
                  </Box>

                  <Box className={classes.inputContainer}>
                    <Typography className={classes.inputLabel}>
                      {this.lastName+"*"}
                    </Typography>
                    <TextField
                      className={classes.inputField}
                      id="lastName"
                      name="lastName"
                      variant="outlined"
                      size="small"
                      placeholder={this.lastName}
                      onChange={handleChange}
                      value={values.lastName}
                      error={touched.lastName && Boolean(errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                      data-test-id="lastNameInput"
                      InputProps={{
                        style: {
                          height: "56px",
                        },
                      }}
                    />
                  </Box>

                  <Box className={classes.inputContainer}>
                    <Typography className={classes.inputLabel}>
                      {this.email+"*"}
                    </Typography>
                    <TextField
                      className={classes.inputField}
                      id="email"
                      name="email"
                      variant="outlined"
                      size="small"
                      placeholder={this.email}
                      onChange={handleChange}
                      value={values.email}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      data-test-id="emailInput"
                      InputProps={{
                        style: {
                          height: "56px",
                        },
                      }}
                    />
                  </Box>
                </Box>

                <Box className={classes.inputContainer}>
                  <Typography className={classes.inputLabel}>
                    {this.mobileNumber+"*"}
                  </Typography>
                  <PhoneInput
                    country={"us"}
                    value={values.fullPhoneNumber}
                    specialLabel={""}
                    data-test-id="phoneInput"
                    onChange={(
                      value: string,
                      _country: string,
                      event: ChangeEvent<HTMLInputElement>
                    ) => {
                      const changeEvent = {
                        target: {
                          id: event?.target?.id,
                          name: event?.target?.name,
                          value: `+${value}`,
                        },
                      };
                      handleChange(changeEvent);
                    }}
                    isValid={!errors.fullPhoneNumber}
                    inputProps={{
                      id: "fullPhoneNumber",
                      name: "fullPhoneNumber",
                      ["data-test-id"]: "phoneInput",
                    }}
                    inputStyle={webStyles.phoneInput}
                  />
                  {errors.fullPhoneNumber && <Typography style={{color: "#f44336", paddingLeft: "15px", fontSize: "12px"}}>
                    {errors.fullPhoneNumber}
                  </Typography>}
                </Box>

                <Box>
                  <Button
                    id="submit"
                    variant="contained"
                    type="submit"
                    disableElevation
                    className={classes.addContactButton}
                    style={{
                      ...webStyles.addContactButton,
                      ...webStyles.button,
                    }}
                    data-test-id="addContactButton"
                  >
                    {contactData ? "Save Changes" : "ADD CONTACT"}
                  </Button>
                </Box>
                <Box>
                  {!hideBackBtn && <Button
                    variant="contained"
                    onClick={setStep}
                    className={classes.backButton}
                    style={{ ...webStyles.backButton, ...webStyles.button }}
                    data-test-id="backButton"
                  >
                    Back
                  </Button>}
                </Box>
              </form>
            );
          }}
        </Formik>
        <Snackbar
          open={Boolean(apiSuccess || apiError)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={this.onCloseSnackbar}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity={apiSuccess ? "success" : "error"}
          >
            {apiSuccess || apiError}
          </Alert>
        </Snackbar>
      </Container>
    );
  }
}

export { AddContactModal };
export default withStyles(styles)(AddContactModal);
// Customizable Area End
